import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../Store/root';


export interface ThemeOption {
  readonly label: String
  readonly value: String
}
interface ThemesState {
  themes: ThemeOption[]
}

interface UpdateTheme {
  previousTheme: ThemeOption
  newLabel: String
}

const STORAGE_KEY = 'stream_themes';

const fallbackInitialState: ThemesState = {
  themes: [
    { label: "🔮 Requests", value: "requests" },
    { label: "🚧 Live learns", value: "live-learns" },
  ],
}

export const themesSlice = createSlice({
  name: 'themes',
  initialState: (): ThemesState => {
    const encodedThemes = localStorage.getItem(STORAGE_KEY);
    if (encodedThemes === null) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(fallbackInitialState));
      return fallbackInitialState;
    }

    return JSON.parse(encodedThemes);
  },
  reducers: {
    createTheme: (state, action: PayloadAction<ThemeOption>) => {
      state.themes.push(action.payload);
      localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
    },
    updateTheme: (state, action: PayloadAction<UpdateTheme>) => {
      for (let i=0;i<state.themes.length;i++) {
        if (state.themes[i].label === action.payload.previousTheme.label) {
          state.themes[i].label = action.payload.newLabel;
          localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
        }
      }
    },
    deleteTheme: (state, action: PayloadAction<ThemeOption>) => {
      state.themes = state.themes.filter((t: ThemeOption) => {
        return t.label !== action.payload.label
      })
      localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
    }
  },
});


export const { createTheme, updateTheme, deleteTheme } = themesSlice.actions;

export const selectThemes = (state: RootState) => state.themes

export default themesSlice.reducer
