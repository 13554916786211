const DefaultClassNames = {
  control: () => 'has-background-dark',
  singleValue: () => 'has-text-light',
  input: () => 'has-text-light',
  menuList: () => 'has-background-dark',
  option: (state: any) => {
    if (state.isSelected) {
      return 'has-background-primary';
    }

    if (state.isFocused) {
      return 'has-background-grey';
    }

    return 'has-background-grey-dark';
  }
}

export { DefaultClassNames }
