import React, { ChangeEvent } from "react"
import { connect, ConnectedProps } from "react-redux"
import { ActionMeta, SingleValue } from "react-select"
import Themes from "../GeneratorForm/Themes"
import { RootState } from "../Store/root"
import { Stream } from "./Stream"
import { removeStream, updateTime, updateTheme } from "./streamsSlice"
import { createTheme, ThemeOption } from "./themes/themesSlice"

class EditStream extends React.Component<EditStreamProps> {
  constructor(props: EditStreamProps) {
    super(props)

    this.changeTime = this.changeTime.bind(this)
    this.changeTheme = this.changeTheme.bind(this)
  }

  changeTime(event: ChangeEvent<HTMLInputElement>) {
    this.props.updateTime({ id: this.props.stream.getId(), time: event.target.value})
  }

  changeTheme(theme: SingleValue<ThemeOption>, action: ActionMeta<ThemeOption>) {
    if (action.action === "create-option" && theme !== null) {
      this.props.createTheme({label: theme.label, value: theme.value})
    }
    this.props.updateTheme({ id: this.props.stream.getId(), theme: theme?.label.toString() || ''})
  }

  render() {
    const stream = this.props.stream
    return <>
      <div className="field">{stream.format('dddd')}</div>
      <div className="field is-grouped">
        <div className="control">
          <input type="time" className="input is-normal" list="streamHours" step="1800" value={stream.getTime()} onChange={this.changeTime} />
        </div>
        <div className="control">
          <Themes onChange={this.changeTheme} selectedTheme={stream.getTheme()} />
        </div>
        <div className="control">
          <button className="button is-danger" onClick={() => { this.props.removeStream(stream.getId()) }}>X</button>
        </div>
      </div>
      
    </>
  }
}

const connector = connect((state: RootState) => {
  return {}
}, {
  removeStream,
  updateTime,
  updateTheme,
  createTheme
})

type EditStreamProps = ConnectedProps<typeof connector> & {stream: Stream}

export default connector(EditStream)
