import React from 'react';
import './App.scss';
import GeneratorForm from './GeneratorForm';
import Preview from './Preview';
import Output from './Output';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from './Store/root';
import Clock from './Header/Clock';
import { changeWeek } from './Stream/streamsSlice';

class App extends React.Component<AppProps> {

  render() {
    return (
      <div className="App">
        <nav className="navbar">
          <div className="navbar-brand">
            <div className="navbar-item">
              <h1 className="title">Schedule generator</h1>
            </div>
          </div>
          <div className="navbar-end">
            <Clock />
          </div>
        </nav>
        <section className="section">
          <div className="container">
            <div className="columns is-mobile">
                <div className="column has-text-left">
                  <button className="button is-info" onClick={() => this.props.changeWeek(this.props.week-1)}>&lt;</button>
                </div>
                <div className="column has-text-centered">
                  <h3 className="is-size-4">Week {this.props.week}</h3>
                </div>
                <div className="column has-text-right">
                  <button className="button is-info" onClick={() => this.props.changeWeek(this.props.week+1)}>&gt;</button>
                </div>
            </div>
            <div className="columns">
              <div className="column">
                <GeneratorForm timezone={this.props.timezone} week={this.props.week} />
              </div>
              <div className="column">
                <Preview />
              </div>
              <div className="column">
                <Output />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const connector = connect((state: RootState) => ({
  streamThemes: state.themes.themes,
  streams: state.streams.streams,
  week: state.streams.week,
  timezone: state.streams.timezone
}), {
  changeWeek,
})

type AppProps = ConnectedProps<typeof connector>

export default connector(App);
