import React from "react";
import { ActionMeta, SingleValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import { RootState } from "../Store/root";
import { ThemeOption, createTheme } from '../Stream/themes/themesSlice'
import { connect, ConnectedProps } from 'react-redux'
import { DefaultClassNames } from "../util/ReactSelect";

type OnChange = (v: SingleValue<ThemeOption>, a: ActionMeta<ThemeOption>) => void;

class Themes extends React.Component<ThemeProps> {
  constructor(props: ThemeProps) {
    super(props)

    this.onCreate = this.onCreate.bind(this);
  }

  onCreate(value: String): void {
    this.props.createTheme({ label: value, value: value });
  }

  render() {
    let selected: ThemeOption|null = null;
    for (let i=0; i<this.props.options.length;i++) {
      if (this.props.options[i].label === this.props.selectedTheme) {
        selected = this.props.options[i];
      }
    }
    return <CreatableSelect<ThemeOption>
      isClearable
      options={this.props.options}
      value={selected}
      isOptionSelected={(option): boolean => {
        return option.label === this.props.selectedTheme;
      }}
      classNames={DefaultClassNames}
      onChange={this.props.onChange}
    />
  }
}

const s2p = (state: RootState) => ({
  options: state.themes.themes
})

const connector = connect(s2p, { createTheme })

type ThemeProps = ConnectedProps<typeof connector> & {
  selectedTheme: String,
  onChange: OnChange,
}

export default connector(Themes);
