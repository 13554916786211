import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { Timezone } from '../Header/Clock';
import { SerializedStream } from './streamsSlice';

export type StreamId = string

export enum WeekDay {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

export class Stream {
  private id: StreamId
  private on: moment.Moment
  private theme: String
  private constructor(id: StreamId, on: moment.Moment, theme: String) {
    this.id = id;
    this.on = on;
    this.theme = theme;
  }

  getId(): StreamId {
    return this.id
  }

  format(format: string): string {
    return this.on.format(format)
  }

  equals(other: Stream): boolean {
    return this.on.isSame(other.on, 'hour')
  }

  setHour(hour: number): void {
    this.on.hour(hour)
  }

  setTime(hour: number, minute: number): void {
    this.setHour(hour)
    this.on.minute(minute)
  }

  setWeek(week: number) {
    this.on.week(week)
  }

  setTimezone(tz: Timezone) {
    let newOffset = this.on.utcOffset()
    switch (tz) {
      case Timezone.MALAYSIA:
        newOffset = 8;
        break;
      case Timezone.BUDAPEST:
        newOffset = 1 + (moment().isDST() ? 1 : 0)
    }
    this.on.utcOffset(newOffset)
  }

  getHour(): number {
    return this.on.hour()
  }

  getTime(): string {
    return this.on.format('HH:mm')
  }
  
  isBefore(streamB: Stream): boolean {
    return this.on.isBefore(streamB.on)
  }

  setTheme(theme: String): void {
    this.theme = theme
  }

  getTheme(): String {
    return this.theme
  }

  resetTheme(): void {
    this.theme = ""
  }

  serialize(): SerializedStream {
    return {
      id: this.id,
      time: this.on.toISOString(true),
      theme: this.theme.toString()
    }
  }

  static unserialize(s: SerializedStream, asTz: Timezone): Stream {
    return new Stream(s.id, moment.parseZone(s.time).tz(asTz), s.theme)
  }
  
  static forDate(on: moment.Moment, theme: String) {
    // truncate anything smaller than a minute
    on.startOf('minute')

    const id = uuidv4();

    return new Stream(id, on, theme);
  }
}
