import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from './Store/root';
import { Stream } from './Stream/Stream';
import StreamPreview from './StreamPreview';

class Preview extends React.Component<PreviewProps> {
  render() {
    const streams = this.props.streams.map((stream, index) => <StreamPreview key={"preview_" + index} stream={stream} isLast={index === (this.props.streams.length-1)} />);

    return <div className="card">
      <div className="card-header-dark">
        <h2 className="card-header-title">Preview</h2>
      </div>
      <div className="card-content">
        <strong><u>Streams for this week:</u></strong><br /><br />
        {streams}<br />
        <i>(all times in your local timezone)</i>
      </div>
    </div>;
    ;
  }
}

const connector = connect((state: RootState) => {
  return {
    streams: state.streams.streams.map(s => Stream.unserialize(s, state.streams.timezone))
  }
})

type PreviewProps = ConnectedProps<typeof connector>

export default connector(Preview);
