import React from 'react';
import { Stream, WeekDay } from './Stream/Stream';
import EditThemes from './GeneratorForm/themes/EditThemes';
import { RootState } from "./Store/root";
import { connect, ConnectedProps } from 'react-redux';
import { Timezone } from './Header/Clock';
import { SerializedStream, addStream } from './Stream/streamsSlice';
import EditStream from './Stream/EditStream';

class GeneratorForm extends React.Component<GeneratorProps> {
  constructor(props: GeneratorProps) {
    super(props)

    this.addStream = this.addStream.bind(this)
  }

  addStream(day: WeekDay) {
    this.props.addStream({ day: day, theme: '', hour: 18})
  }
  render() {
    return <div className="box">
        <p>Add stream on</p>
        <div className="field is-grouped">
          <p className="control">
            <button className="button is-primary" onClick={() => this.addStream(WeekDay.MONDAY)}>
              <span>Monday</span>
            </button>
          </p>
          <p className="control">
            <button className="button is-primary" onClick={() => this.addStream(WeekDay.TUESDAY)}>
              <span>Tuesday</span>
            </button>
          </p>
          <p className="control">
            <button className="button is-primary" onClick={() => this.addStream(WeekDay.WEDNESDAY)}>
              <span>Wednesday</span>
            </button>
          </p>
        </div>
        <div className="field is-grouped">
          <p className="control">
            <button className="button is-primary" onClick={() => this.addStream(WeekDay.THURSDAY)}>
              <span>Thursday</span>
            </button>
          </p>
          <p className="control">
            <button className="button is-primary" onClick={() => this.addStream(WeekDay.FRIDAY)}>
              <span>Friday</span>
            </button>
          </p>
          <p className="control">
            <button className="button is-primary" onClick={() => this.addStream(WeekDay.SATURDAY)}>
              <span>Saturday</span>
            </button>
          </p>
          <p className="control">
            <button className="button is-primary" onClick={() => this.addStream(WeekDay.SUNDAY)}>
              <span>Sunday</span>
            </button>
          </p>
        </div>
        <datalist id="streamHours">
          <option value="14:00" />
          <option value="18:00" />
        </datalist>
        <div className="field">
          {this.props.streams.map(
            stream => <EditStream key={stream.getId()} stream={stream} />
          )}
        </div>
        <div className="field">
          <EditThemes />
        </div>
      </div>;
  }
}

const connector = connect((state: RootState) => ({
    streams: state.streams.streams.map((s: SerializedStream) => Stream.unserialize(s, state.streams.timezone))
}), {
  addStream
})

type GeneratorProps = ConnectedProps<typeof connector> & {
  timezone: Timezone
  week: number
}

export default connector(GeneratorForm)
