import React, { ReactNode } from "react";
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from "../../Store/root";
import { ThemeOption, deleteTheme } from "../../Stream/themes/themesSlice";
import EditTheme from "./EditTheme";

class EditThemes extends React.Component<EditProps> {

  render() {
    return <div className="block">
      <h4 className="subtitle">Theme types</h4>
      {this.props.streamThemes.map((item: ThemeOption, key: any): ReactNode => {
        return <EditTheme key={key} theme={item} themeDeleted={() => this.props.deleteTheme(item)} />;
      })}
    </div>;
  }
}

const connector = connect((state: RootState) => ({
  streamThemes: state.themes.themes
}), {
  deleteTheme
});

type EditProps = ConnectedProps<typeof connector> & {
  streamThemes: ThemeOption[],
}
export default connector(EditThemes)
