import moment from "moment";
import { Moment } from "moment";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import Select, { components, OptionProps, SingleValue, SingleValueProps } from "react-select";
import { RootState } from "../Store/root";
import { updateTimezone } from "../Stream/streamsSlice";
import { DefaultClassNames } from "../util/ReactSelect";

type ClockState = {
  time: Moment
}

export enum Timezone {
  MALAYSIA = 'Asia/Kuala_Lumpur',
  BUDAPEST = 'Europe/Budapest',
}

type TimezoneOption = {
  tz: Timezone
  flag: string
}

const TimezoneValueComponent = (props: SingleValueProps<TimezoneOption>) => {
  return (<components.SingleValue {...props}>
    <span className="icon-text">
      <span />
      <span className="icon">
        <img src={"https://flagcdn.com/32x24/" + props.data.flag + ".png"} alt={props.data.tz} title={props.data.tz} />
      </span>
      <span />
    </span>
  </components.SingleValue>)
}

const TimezoneOptionComponent = (props: OptionProps<TimezoneOption>) => {
  return (<components.Option {...props}>
    <span className="icon"><img src={"https://flagcdn.com/32x24/" + props.data.flag + ".png"} alt={props.data.tz} title={props.data.tz} /></span>
  </components.Option>)
}

class Clock extends React.Component<ClockProps, ClockState> {
  private timerId?: number
  private options: TimezoneOption[]
  constructor(props: ClockProps) {
    super(props)

    this.options = [
      { tz: Timezone.BUDAPEST, flag: "hu" },
      { tz: Timezone.MALAYSIA, flag: "my" },
    ]

    this.state = { time: moment() }

    this.onChange = this.onChange.bind(this)
  }

  componentDidMount(): void {
    this.timerId = window.setInterval(() => {
      this.setState((_state: ClockState) => {
        return { time: moment.tz(this.props.timezone) };
      })
    }, 1000);
  }

  componentWillUnmount(): void {
    window.clearInterval(this.timerId);
  }

  getValueOption(): TimezoneOption|undefined {
    return this.options.filter(o => o.tz === this.props.timezone).at(0)
  }

  onChange(value: SingleValue<TimezoneOption>): void {
    if (value === null) {
      return
    }
    this.props.updateTimezone(value.tz)
  }

  render() {
    return <>
      <div className="navbar-item">
        <Select<TimezoneOption>
          onChange={this.onChange}
          value={this.getValueOption()}
          options={this.options}
          classNames={DefaultClassNames}
          isOptionSelected={(option): boolean => {
            return option.tz === this.props.timezone;
          }}
          components={{
            SingleValue: TimezoneValueComponent,
            Option: TimezoneOptionComponent
          }}
        />
      </div>
      <div className="navbar-item">
        {this.state.time.format('[Week] ww - dddd HH:mm:ss')}
      </div>
    </>
  }
}

const connector = connect((state: RootState) => ({
  timezone: state.streams.timezone
}), {
  updateTimezone
})

type ClockProps = ConnectedProps<typeof connector>

export default connector(Clock)
