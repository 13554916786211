import React, { ChangeEvent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from './Store/root';
import { Stream } from './Stream/Stream';

type OutputState = {
  customized: boolean,
};

class Output extends React.Component<OutputProps, OutputState>
{
  private customizedTextarea: string;
  constructor(props: OutputProps) {
    super(props);

    this.state = {
        customized: false
    };

    this.customizedTextarea = this.generateOutput(props.streams);
    this.handleChange = this.handleChange.bind(this);
    this.reset = this.reset.bind(this);
  }

  private handleChange(e: ChangeEvent<HTMLTextAreaElement>): void {
    this.customizedTextarea = e.target.value;
    this.setState({ customized: true });
  }

  private reset(): void {
    this.setState({ customized: false });
    this.customizedTextarea = this.generateOutput(this.props.streams);
  }

  private generateOutput(streams: Stream[]): string {
    let output = '**__Streams for this week__**:\n\n';
    const streamsOutput = streams.map((stream: Stream) => {
      let theme = stream.getTheme() !== '' ? '\n' + stream.getTheme() : '';
      return '<t:' + stream.format('X') + ':F>' + theme;
    });

    output += streamsOutput.join('\n————————\n');
    output += '\n\n*(all times in your local timezone)*';

    return output;
  }

  render() {
    let rendered = this.state.customized ? this.customizedTextarea : this.generateOutput(this.props.streams);
    return <div className="form">
      <div className="field">
        <textarea className="textarea output" value={rendered} onChange={this.handleChange} />
      </div>
      <div className="field is-grouped">
        <div className="control">
          <button className="button is-primary" onClick={() => navigator.clipboard.writeText(rendered)}>Copy to clipboard</button>
        </div>
        <div className="control">
          <button className={"button is-warning" + (this.state.customized ? "" : " is-hidden")} onClick={this.reset}>Reset</button>
        </div>
      </div>
    </div>;
  }
}

const connector = connect((state: RootState) => {
  return {
    streams: state.streams.streams.map(s => Stream.unserialize(s, state.streams.timezone))
  }
})

type OutputProps = ConnectedProps<typeof connector>

export default connector(Output)
