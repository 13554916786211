import React, { ChangeEvent } from "react"
import { connect, ConnectedProps } from "react-redux"
import { ThemeOption, updateTheme, deleteTheme } from "../../Stream/themes/themesSlice"
import { themeUpdated } from "../../Stream/streamsSlice"


type EditThemeState = {
  editMode: boolean
  label: String
}

class EditTheme extends React.Component<EditThemeProps, EditThemeState> {
  constructor(props: EditThemeProps) {
    super(props);

    this.state = { editMode: false, label: props.theme.label };

    this.updateTheme = this.updateTheme.bind(this);
    this.deleteTheme = this.deleteTheme.bind(this);
    this.saveTheme = this.saveTheme.bind(this);
  }

  updateTheme(e: ChangeEvent<HTMLInputElement>) {
    this.setState((state: EditThemeState) => {
      if (state.label === e.target.value) {
        return;
      }

      state.label = e.target.value;
      return state;
    });
  }

  saveTheme() {
    this.setState((prevState: EditThemeState) => {
      if (prevState.editMode) {
        this.props.updateTheme({ previousTheme: this.props.theme, newLabel: this.state.label})
        this.props.themeUpdated({
          previousTheme: this.props.theme.label.toString(),
          newTheme: this.state.label.toString()
        })
        return { editMode: false }
      }

      return prevState
    })
  }

  deleteTheme() {
    this.props.deleteTheme(this.props.theme)
    this.props.themeDeleted(this.props.theme)
  }

  render() {
    if (this.state.editMode) {
      return <div className="form">
        <div className="field has-addons">
          <div className="control">
            <input type="text" className="input" value={this.state.label.toString()} onChange={this.updateTheme} />
          </div>
          <div className="control">
            <button className="button is-primary" onClick={this.saveTheme}>
              <div className="icon is-small">
                💾
              </div>
            </button>
          </div>
        </div>
      </div>;
    }
    return <div className="columns">
      <div className="column">
        {this.props.theme.label}
      </div>
      <div className="column is-4">
        <div className="field is-grouped">
          <div className="control">
            <button className="button is-primary" onClick={() => this.setState({editMode: true})}>
              <div className="icon is-small">
                <i className="fas fa-edit">📝</i>
              </div>
            </button>
          </div>
          <div className="control">
            <button className="button is-danger" onClick={this.deleteTheme}>
              <div className="icon is-small">⛔</div>
            </button>
          </div>
        </div>
      </div>
    </div>;
  }
}

const connector = connect(undefined, {updateTheme, deleteTheme, themeUpdated})

type EditThemeProps = ConnectedProps<typeof connector> & {
  theme: ThemeOption
  themeDeleted: any,
}

export default connector(EditTheme)
