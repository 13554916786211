import { configureStore } from '@reduxjs/toolkit';
import themesReducer from '../Stream/themes/themesSlice';
import streamsReducer from '../Stream/streamsSlice';

export const store = configureStore({
  reducer: {
    themes: themesReducer,
    streams: streamsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

