import { Stream } from "./Stream/Stream";

function StreamPreview(props: {stream: Stream, isLast: boolean}) {
  const theme = props.stream.getTheme() !== '' ? <p>{props.stream.getTheme()}</p> : '';
  return <div>
    <p>{props.stream.format('dddd DD MMMM YYYY HH:mm')}</p>
    {theme}
    {!props.isLast ? <p>------------------</p> : ''}
  </div>;
}

export default StreamPreview;
