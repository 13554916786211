import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { Stream, StreamId, WeekDay } from './Stream';
import { Timezone } from '../Header/Clock';

export enum StreamsStoreState {
  UNINITIALIZED,
  LOADING,
  READY
}

export type SerializedStream = {
  id: string
  time: string
  theme: string
}

type StreamsState = {
  state: StreamsStoreState
  streams: SerializedStream[]
  week: number
  timezone: Timezone
}

type BaseStreamAction = {
  id: StreamId,
}

type UpdateTime = BaseStreamAction & { time: string }
type UpdateTheme = BaseStreamAction & { theme: string }
type AddStream = { day: WeekDay, theme: string, hour: number }
type ThemeUpdated = { previousTheme: string, newTheme: string }
type ThemeDeleted = { previousTheme: string }
export const streamsSlice = createSlice({
  name: 'streams',
  initialState: (): StreamsState  => {
    let utcOffset = moment().utcOffset()
    let defaultTimezone = Timezone.BUDAPEST
    if (utcOffset > 120) {
      defaultTimezone = Timezone.MALAYSIA
    }

    const weekOffset = moment.tz(defaultTimezone).weekday() >= 4 ? 1 : 0;
    const currentWeek = moment.tz(defaultTimezone).add(weekOffset, 'week').week();

    const forDay = (day: WeekDay) => moment().tz(defaultTimezone).week(currentWeek).weekday(day).hour(16).startOf('hour')
    const streams = [
      Stream.forDate(forDay(WeekDay.MONDAY), '').serialize(),
      Stream.forDate(forDay(WeekDay.WEDNESDAY), '').serialize(),
      Stream.forDate(forDay(WeekDay.FRIDAY), '').serialize(),
    ];

    return {
      state: StreamsStoreState.READY,
      streams: streams,
      week: currentWeek,
      timezone: defaultTimezone
    }
  },
  reducers: {
    addStream: (state: StreamsState, action: PayloadAction<AddStream>) => {
      const date = moment().tz(state.timezone).week(state.week).weekday(action.payload.day).hour(action.payload.hour).startOf('hour')
      state.streams.push(Stream.forDate(date, action.payload.theme).serialize())
    },
    removeStream: (state: StreamsState, action: PayloadAction<StreamId>) => {
      state.streams = state.streams.filter(s => s.id !== action.payload)
    },
    updateTime: (state: StreamsState, action: PayloadAction<UpdateTime>) => {
      const index = state.streams.findIndex(s => s.id === action.payload.id)
      if (index === -1) {
        throw new Error("unable to find stream with id " + action.payload.id)
      }

      const stream = Stream.unserialize(state.streams[index], state.timezone)
      const parts = action.payload.time.split(':');
      const hour = parseInt(parts[0], 10);
      const minute = parseInt(parts[1], 10);
      if (stream.getTime() !== action.payload.time) {
        stream.setTime(hour, minute);
        state.streams[index] = stream.serialize()
      }
    },
    updateTheme: (state: StreamsState, action: PayloadAction<UpdateTheme>) => {
      const index = state.streams.findIndex(s => s.id === action.payload.id)
      if (index === -1) {
        throw new Error("unable to find stream with id " + action.payload.id)
      }
      
      state.streams[index].theme = action.payload.theme
    },
    changeWeek: (state: StreamsState, action: PayloadAction<number>) => {
      state.week = action.payload
      state.streams.forEach((s: SerializedStream, index: number) => {
        const stream = Stream.unserialize(s, state.timezone)
        stream.setWeek(state.week)
        state.streams[index] = stream.serialize()
      })
    },
    updateTimezone: (state: StreamsState, action: PayloadAction<Timezone>) => {
      state.timezone = action.payload
      state.streams.forEach((s: SerializedStream, index: number) => {
        const stream = Stream.unserialize(s, state.timezone)
        stream.setTimezone(state.timezone)
        state.streams[index] = stream.serialize()
      })
    },
    themeDeleted: (state: StreamsState, action: PayloadAction<ThemeDeleted>) => {
      state.streams.forEach((stream: SerializedStream) => {
        if (stream.theme === action.payload.previousTheme) {
          stream.theme = ''
        }
      })
    },
    themeUpdated: (state: StreamsState, action: PayloadAction<ThemeUpdated>) => {
      state.streams.forEach((stream: SerializedStream) => {
        if (stream.theme === action.payload.previousTheme) {
          stream.theme = action.payload.newTheme
        }
      })
    },
  },
})

export const {
  addStream,
  removeStream,
  updateTime,
  updateTheme,
  changeWeek,
  updateTimezone,
  themeUpdated
} = streamsSlice.actions;

export default streamsSlice.reducer
